.projects-container{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10%;
    background: rgb(212,247,255);
background: linear-gradient(353deg, rgba(212,247,255,1) 9%, rgba(255,232,206,1) 57%);

}
.project-header{
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 20px;
    margin-top: 0 !important;
    color: #393e46
}
.project-list{
    height: 90%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.image-container{
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin: 5%;
    
    position: relative;
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
}
.image-container img{
    width: 100%;
    height: 100%;
}
.image-container h3{
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
}
.transition-image{
    position: absolute;
    border-radius: 15px;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.transition-image:hover{
    opacity: 1;
}


@media only screen and (min-width: 768px) and (min-height: 1024px) {

    .project-header{
        font-size: 35px;
        font-weight: 500;
    }   
    
}


@media only screen and (min-width: 1024px)and (min-height: 1366px){
    
    .project-list{
        
        height: 70%;
        width: 80%;
    }
    .image-container{
       
        width: 300px;
    }
    .image-container h3{
        font-size: 30px;
    }
}@media only screen and (min-width: 768px){
    .projects-container h2{
        font-size: 30px;
       
    }

    .project-list {
        flex-direction: row;
    }
}