.container{
    height: 100vh;
    
    
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

    background:url('./assets/water-big.jpg');
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.8;
}
h1{
   
    font-family: 'Raleway', sans-serif;
    font-weight: 300 !important;
    
}
.layers{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 90%;
    height: 90%;
   
}
.base{
    margin-top: 14%;
}

.overlay{
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
height: 100%;
width: 100%;
top: 0;
opacity: 0;
transition: opacity 1.5s ease;
}
.overlay h2{
margin-top: 15%;
font-family: 'Raleway', sans-serif;
    font-weight: 300 !important;
}
.overlay:hover{
    opacity: 1;
}
.container img{
    
    width: 8%;
   
    opacity: 0.5;
}
.container img:hover{
    cursor: pointer;
}


@media only screen and (min-width: 768px){

    .overlay img{
        width: 5%;
    }
}

@media only screen and (min-width: 1224px) {
    .container h1{
        font-size: 42px;
    
    }
   .overlay h2{
       margin-top: 18%;
       font-size: 42px;
   }
}
