.contact-container{
   
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: rgb(212,247,255);
    background: linear-gradient(180deg, rgba(212,247,255,1) 9%, rgba(255,232,206,1) 57%);
    
}
.contact-container h2{
    margin-top: 0 !important;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    color:  #393e46
}
.contact-methods{
    display: flex;
    justify-content: center;
    width: 90%;
    justify-content: space-around;
    padding-bottom: 3%;
   
}

.contact img{
    width: 30%;
    padding: none;
}

.contact h3{
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    color: #393e46

}

.contact-container h4{
    font-family: 'Raleway', sans-serif;
    font-weight: 300;

}
a{
    text-decoration: none !important;

}

@media only screen and (min-width: 1024px)  {

    .contact-container h2{
        font-size: 35px;
    }
    .contact-container h4{
        font-size: 30px;
    }
    .contact img{
        width: 20%;
    }
    .contact h3{
        font-size: 20px;
    }
}